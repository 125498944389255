@import url(https://fonts.googleapis.com/css?family=Montserrat:black,regular,bold,light,medium);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  /*margin: 0;*/
}

.app-background {
  width: 100%;
  margin: 0 auto;
}
.app-background textarea:focus, .app-background input:focus {
  outline: none;
}
.app-background .montserrat-light-font {
  font-family: Montserrat;
  font-weight: 300;
}
.app-background .montserrat-medium-font {
  font-family: Montserrat;
  font-weight: 500;
}
.app-background .small-font {
  font-size: 15px;
}
.app-background .medium-font {
  font-size: 18px;
}
.app-background .bold-font {
  font-weight: bold;
}
.app-background .large-title-font {
  font-size: 30px;
}
.app-background .hidden-submit-button {
  display: none;
}
.app-background .center-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-background .full-width {
  width: 100%;
}
.app-background .default-button-border {
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  cursor: pointer;
}
.app-background a {
  color: inherit;
  text-decoration: none;
}
.app-background .splash-title-text {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.app-background .reset-password-background {
  margin-bottom: 100px;
}
.app-background .reset-password-background .header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(#259BE4, #0174B9);
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.app-background .reset-password-background .logo-icon {
  width: 80%;
  max-width: 400px;
}
.app-background .reset-password-background .padlock-icon {
  margin-top: 80px;
  margin-bottom: 30px;
}
.app-background .reset-password-background .reset-password-title {
  color: #0174B9;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.app-background .reset-password-background .reset-password-description {
  margin-top: 40px;
  margin-bottom: 70px;
  font-size: 17px;
  color: #656565;
  text-align: center;
}
.app-background .reset-password-background .reset-password-form {
  width: 80%;
}
.app-background .reset-password-background .text-input-container {
  background-color: #f2f2f3;
  margin-bottom: 45px;
  border-radius: 10px;
  max-width: 360px;
  width: 100%;
}
.app-background .reset-password-background .text-input-container .text-input {
  background-color: transparent;
  border-color: transparent;
  padding: 15px;
  font-size: 16px;
  width: calc(100% - 30px);
}
.app-background .reset-password-background .reset-password-button {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  color: white;
  background-image: linear-gradient(#259BE4, #0174B9);
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  max-width: 320px;
  width: calc(100% - 40px);
}
.app-background .reset-password-background .message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #656565;
  text-align: center;
  height: calc(100vh - 306px);
  width: 80%;
}
.app-background .reset-password-background .loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #656565;
  text-align: center;
  height: calc(100vh - 306px);
  width: 80%;
}

/*# sourceMappingURL=main.css.map */

